import './style.css'
function App() {
  return (
      <div className="performance-summary">
          <div className='content-performance-title'>
              <p className="performance-title text-primary">Total Profit</p>
              <p className="performance-title text-primary">Total Stakes</p>
              <p className="performance-title text-primary">Active bonuses</p>
          </div>

          <div className='content-performance-value'>
              <p className="performance-value">0%</p>
              <p className="performance-value">0</p>
              <p className="performance-value">0</p>
          </div>
      </div>
  );
}

export default App;
