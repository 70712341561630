import { useEffect } from 'react';

import { isFirstLoad, isSingin, userData } from '@redux/slices/initLoad/types';
import { store } from '@redux/store';
import requests from 'helpers/requests';
import { authLogin } from 'hooks/useAuth';

interface TelegramWebAppUser {
  id: number;
  first_name: string;
  last_name: string;
  username?: string;
  photo_url?: string;
}

export const useInit = () => {
  const getInitData = async () => {
    store.dispatch(isFirstLoad(true));
    store.dispatch(isSingin(false));

    if (window.Telegram && window.Telegram.WebApp) {
      if (window.Telegram.WebApp.initData) {
        const initData = window.Telegram.WebApp.initData;

        const urlParams = new URLSearchParams(initData);

        const userData = JSON.parse(
          urlParams.get('user') as string,
        ) as TelegramWebAppUser;

        const userId = userData.id;
        const authDate = urlParams.get('auth_date');
        const hash = urlParams.get('hash');

        const data = {
          provider: 'telegram',
          provider_user_id: userId,
          name: `${userData.first_name} ${userData.last_name}`,
          provider_data: {
            id: userId,
            first_name: userData.first_name,
            last_name: userData.last_name,
            username: userData.username,
            photoProfile: userData.photo_url,
            photo_url: '',
            auth_date: authDate ?? 0,
            hash: hash ?? '',
          },
        };

        authLogin(data)
          .then((response) => {
            console.log({ response });
          })
          .catch((error: any) => {
            alert(error);
            console.log('error', error);
          });
      } else {
        verifyAuth();
      }
    } else {
      console.log('Telegram WebApp is not available');
    }
    verifyAuth();
  };

  const verifyAuth = async () => {
    try {
      const response = await requests.post('verifyAuth');

      store.dispatch(isSingin(true));
      store.dispatch(isFirstLoad(false));

      localStorage.setItem('@userData', JSON.stringify(response.data));
      store.dispatch(
        userData({
          username: response.data.name,
          photo_profile: response.data.photo_profile,
        }),
      );
    } catch (error) {
      store.dispatch(isFirstLoad(false));
      store.dispatch(isSingin(false));
      localStorage.removeItem('@userData');
      localStorage.removeItem('@tokenAuth');
      console.error('Error verifying authentication:', error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      store.dispatch(isFirstLoad(true));
      getInitData();
    }, 3000);
  }, []);
};
