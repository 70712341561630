import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Splash from '@components/splash';
import SingInScreen from '@screens/singIn';
import HomeScreen from '@screens/home';
import StakeScreen from '@screens/stake';
import WalletScreen from '@screens/wallet';
import FrensScreen from '@screens/frens';
import useScrollToTop from 'hooks/useScrollTopNav';
import { useInit } from 'hooks/useInit';
import { useSelector } from 'react-redux';
import { AppStateType } from '@redux/store';
import useFirebase from 'hooks/useFirebase';

const RootNavigator = () => {
  useInit();
  useFirebase();

  const { isFirstLoad, isSingin } = useSelector(
    (state: AppStateType) => state.initLoad,
  );

  return (
    <>
      {isFirstLoad ? (
        <Splash />
      ) : (
        <Router>
          <ScrollToTop />
          <Routes>
            {!isSingin ? (
              <>
                <Route path="/" element={<SingInScreen />} />
                <Route path="/sing-in" element={<SingInScreen />} />
              </>
            ) : (
              <>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/dashboard" element={<HomeScreen />} />
                <Route path="/stake" element={<StakeScreen />} />
                <Route path="/wallet" element={<WalletScreen />} />
                <Route path="/frens" element={<FrensScreen />} />
              </>
            )}
          </Routes>
        </Router>
      )}
    </>
  );
};

const ScrollToTop: React.FC = () => {
  useScrollToTop();
  return null;
};
export default RootNavigator;
