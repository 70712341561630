import { useState } from 'react';
import Layout from '@components/layout';
import Header from '@components/header';
import ActivitySummary from '@components/activitySummary';
import Task from '@components/task';
import ExtraTask from '@components/extraTask';
import SwipeableComponent from '@components/swipeable';

import './style.css';
function App() {
  const components = [  <Task />, <ExtraTask />];
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % components.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + components.length) % components.length);
  };

    return (
        <Layout>
          <Header />
          <ActivitySummary />
          <div style={{position: 'relative', paddingBottom: 200}}>
            <button className="arrow left-arrow" onClick={handlePrev}>
              <i className="fas fa-chevron-left"></i>
            </button>
            <SwipeableComponent
              currentItem={currentIndex}
              components={components}
              onIndexChange={()=>{}}
            />
            <button className="arrow right-arrow" onClick={handleNext}>
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
        </Layout>
      );
}

export default App;
