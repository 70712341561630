// src/components/ButtonWithRipple.tsx

import React from 'react';
import './style.css';

type Props = {
    label?: string;
    size?: 'xs' | 'md' | 'lg';
    icon?: string;
      onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Index: React.FC<Props> = ({ label="", icon, size='md', onClick }) => {
  const createRipple = (event: React.MouseEvent<HTMLButtonElement>) => {
    const button = event.currentTarget;
    const circle = document.createElement('span');
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add('ripple-effect');

    const ripple = button.getElementsByClassName('ripple-effect')[0];
    if (ripple) {
      ripple.remove();
    }

    button.appendChild(circle);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    createRipple(event);
    if (onClick) {
      onClick(event);
    }
  };

    const buttonClass = `button text-white button-${size}`;
  return (
      <button className={buttonClass} onClick={handleClick}>
          
          {icon ? (
              <i className={icon}></i>
          ): (
            <div className='label'>{label}</div>
          )}
      
    </button>
  );
};

export default Index;
