import { useState } from 'react';
import TelegramLogin from '@components/auth/telegram';
import './style.css';
import LogoBlack from '@assets/logos/black.svg';
import { authLogin } from 'hooks/useAuth';
import { AuthProps } from 'types/auth';
import GoogleBtnAuth from '@components/auth/google';
import AppleBtnAuth from '@components/auth/apple';

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const authProiver = (data: AuthProps) => {
    setLoading(true);
    /* const data = {
      provider: 'telegram',
      provider_user_id: 1549073170,
      name: 'Carlos Cardenas',
      provider_data: {
        id: 1549073170,
        first_name: 'Carlos',
        last_name: 'Cardenas',
        auth_date: 1723049786,
        hash: 'ebe7680e81dc10d0021927d2f20bcf269fef94200c2ece40cb76849b98efe91b',
      },
    };*/
    authLogin(data)
      .then((response) => {
        setLoading(false);
        console.log({ response });
      })
      .catch((error: any) => {
        setLoading(false);
        alert('A ocurrido un error');
        console.log('error', error);
      });
  };
  return (
    <div className="login-container">
      {loading && <div className="loadingAuth">laoding</div>}

      <div className="login-left">
        <div className="login-content">
          <img src={LogoBlack} alt="Logo" className="logo" />
          <h1>Log in to your Account</h1>
          <p>Welcome back! Select method to log in:</p>

          <div className="social-login">
            {/*<button className="btn-provider google-login" onClick={authProiver}>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/2048px-Telegram_logo.svg.png"
                alt="google"
              />
              Telegram
            </button>*/}

            <GoogleBtnAuth authMethod={authProiver} />
            <AppleBtnAuth authMethod={authProiver} />
          </div>
          <TelegramLogin authMethod={authProiver} />
        </div>
      </div>
      <div className="login-right">
        <div className="promo-content">
          <h2>Connect with every application.</h2>
          <p>Everything you need in an easily customizable dashboard.</p>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
