// src/components/ButtonWithRipple.tsx

import React from 'react';
import './style.css';

type Props = {
  label?: string;
  placeholder?: string;
  size?: 'xs' | 'md' | 'lg';
  icon?: string;
  action?: string,
  disabled?: boolean
}

const Index: React.FC<Props> = ({ placeholder="", size='xs', label='', action='', disabled=false }) => {
  
  const inputClass = `input-${size}`;

  return (
    <>
      {label !== '' &&
        <label className='label-input'>{ label }</label>
      }
      <div className='content-input'>
        <input type="text" className={inputClass} placeholder={placeholder} disabled={disabled} />
        {action !== '' &&
          <span className='span-action'>{ action }</span>
        }
      </div>
    </>
  );
};

export default Index;
