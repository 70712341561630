import { AuthProps, TelegramUser } from 'types/auth';
import {
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider,
} from 'firebase/auth';
import { auth } from '@hooks/useFirebase/firebase';

declare global {
  interface Window {
    onTelegramAuth: (user: TelegramUser) => void;
  }
}

type Props = {
  authMethod: (data: AuthProps) => void;
};
const AppleBtnAuth = ({ authMethod }: Props) => {
  const provider = new OAuthProvider('apple.com');

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const credential = OAuthProvider.credentialFromResult(result);
      //const accessToken = credential?.accessToken;
      //const idToken = credential?.idToken;

      if (credential) {
        const user = result.user;
        const data = {
          provider: 'apple',
          provider_user_id: user.uid,
          name: user.displayName,
          email: user.email,
          photoProfile: user.photoURL,
        };
        authMethod(data);
        //console.log('User:', data);
      }
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  return (
    <button className="btn-provider google-login" onClick={signInWithGoogle}>
      <img
        src="https://ourdeal.co.uk/wp-content/uploads/2023/04/Why-Cant-I-Sign-into-My-Apple-ID-How-Can-I-Solve-This.jpg"
        alt="apple"
      />
      Apple ID
    </button>
  );
};

export default AppleBtnAuth;
