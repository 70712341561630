import { useEffect } from 'react';
import { AuthProps, TelegramUser } from 'types/auth';

declare global {
  interface Window {
    onTelegramAuth: (user: TelegramUser) => void;
  }
}

type Props = {
  authMethod: (data: AuthProps) => void;
};
const TelegramLogin = ({ authMethod }: Props) => {
  useEffect(() => {
    window.onTelegramAuth = function (user: TelegramUser) {
      console.log({ user });
      const data = {
        provider: 'telegram',
        provider_user_id: user.id,
        name: user.first_name,
        provider_data: user,
        photoProfile: user.photo_url,
      };
      authMethod(data);
    };

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://telegram.org/js/telegram-widget.js?22';
    script.setAttribute('data-telegram-login', 'krlosexe_bot');
    script.setAttribute('data-size', 'large');
    script.setAttribute('data-onauth', 'onTelegramAuth(user)');
    script.setAttribute('data-request-access', 'write');
    document.getElementById('telegram-login')?.appendChild(script);

    return () => {
      document.getElementById('telegram-login')?.removeChild(script);
    };
  }, [authMethod]);

  return <div id="telegram-login"></div>;
};

export default TelegramLogin;
