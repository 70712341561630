import { isSingin, userData } from '@redux/slices/initLoad/types';
import { store } from '@redux/store';
import requests from 'helpers/requests';
import { AuthProps } from 'types/auth';

export const authLogin = async (data: AuthProps) => {
  try {
    const response = await requests.post('auth', data);
    store.dispatch(isSingin(true));
    localStorage.setItem('@tokenAuth', response.data.access_token);
    localStorage.setItem('@userData', JSON.stringify(response.data.user));
    store.dispatch(
      userData({
        username: response.data.user.name,
        photo_profile: response.data.user.photo_profile,
      }),
    );
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error?.response?.data?.message || 'An unexpected error occurred';
    throw new Error(errorMessage);
  }
};
