import './style.css'
import Buttom from '@components/buttons';
import {InputText} from '@components/forms';
function App() {

  return (
      <div className="task-list">
            <div className="task-header">
                <h3 className="task-title text-primary">EXTRA TASK</h3>
                <p className="task-description">Publish a video or share a post on social networks where you mention Mining Options and Get up to 15% bonus on your first mining contract</p>
            </div>
            <div className="extra-task-list">
                <div className="extra-task-item">
                      <div className='extra-task-content-input'>
                        <div className='content-icon-extra-task'>
                          <i className={`task-icon fab fa-tiktok`}></i>
                        </div>
                        <InputText placeholder='TikTok post link +5%' size='xs'/>
                      </div>
                      <Buttom icon='fas fa-check' size='md'/>
                  </div>
                  <div className="extra-task-item">
                    <div className='extra-task-content-input'>
                        <div className='content-icon-extra-task'>
                          <i className={`task-icon fab fa-instagram`}></i>
                        </div>
                        <InputText placeholder='Instagram post link +5%' size='xs'/>
                      </div>
                      <Buttom icon='fas fa-check' size='md'/>
                  </div>
                  <div className="extra-task-item">
                    <div className='extra-task-content-input'>
                        <div className='content-icon-extra-task'>
                          <i className={`task-icon fab fa-youtube`} style={{marginLeft: "-4px"}}></i>
                        </div>
                        <InputText placeholder='Youtube post link +5%'size='xs'/>
                      </div>
                      <Buttom icon='fas fa-check' size='md'/>
                  </div>
              </div>
            <div className="task-footer">
               <p className="task-description">The posts or videos with the greatest creativity or reach will be participating for 500 USDT. Participation closes on 1-9-2024</p>
            </div>
        </div>
  );
}

export default App;
