import { createAction } from '@reduxjs/toolkit';

type UserData = {
  username: string;
  photo_profile: string;
};
export interface IAuthState {
  isFirstLoad: boolean;
  isSingin: boolean;
  user: UserData;
}

export const initialState: IAuthState = {
  isFirstLoad: true,
  isSingin: false,
  user: {
    username: '',
    photo_profile: '',
  },
};

export const isFirstLoad = createAction<boolean>('root/isFirstLoad');
export const isSingin = createAction<boolean>('root/isSingin');
export const userData = createAction<UserData>('root/userData');
