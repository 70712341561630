import { useSelector } from 'react-redux';
import './style.css';
import LogoWhite from '@assets/logos/white.svg';
import { AppStateType } from '@redux/store';

function App() {
  const { user } = useSelector((state: AppStateType) => state.initLoad);
  return (
    <div className="contentHeader">
      <img src={LogoWhite} alt="MiningOptions" className="logo" />
      <h5>{user.username}</h5>
      <img className="avatarImage" src={user.photo_profile} alt="" />
    </div>
  );
}

export default App;
