import React from 'react';
import './style.css';
import { Link, useLocation } from 'react-router-dom';

const BottomNavigation: React.FC = () => {
    const location = useLocation();
  return (
      <div className="bottom-navigation-content">
          <div className='bottom-navigation'>
            <Link to="/">
                <div className={`nav-item ${location.pathname === '/' ? 'activaitemnav' : ''}`}>
                    <i className="fas fa-home"></i>
                    <span>Home</span>
                </div>
            </Link>
            <Link to="/stake">
                <div className={`nav-item ${location.pathname === '/stake' ? 'activaitemnav' : ''}`}>
                    <i className="fab fa-mixcloud"></i>
                    <span>Stake</span>
                </div>
            </Link>
            <Link to="/frens">
                <div className={`nav-item ${location.pathname === '/frens' ? 'activaitemnav' : ''}`}>
                    <i className="fas fa-users"></i>
                    <span>Frens</span>
                </div>
            </Link>
            <Link to="/wallet">
                <div className={`nav-item ${location.pathname === '/wallet' ? 'activaitemnav' : ''}`}>
                    <i className="fas fa-wallet"></i>
                    <span>Wallet</span>
                </div>
            </Link>
        </div>
    </div>
  );
};

export default BottomNavigation;
