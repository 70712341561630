
import React from 'react';
import BottomNav from '@components/bottomNav';
import './style.css';

type Props = {
  title?: string
  children: React.ReactNode;
  icon?: boolean;
};


const Layout: React.FC<Props> = ({ title="", children, icon=true}) => {
 
  return (
    <div className="App">
      {title !== "" && 
        <div id='layout-title'>
          {icon && <img src={"https://static-00.iconduck.com/assets.00/tether-cryptocurrency-icon-2048x2048-dp13oydi.png"} alt="" />}
          <h3>{ title }</h3>
        </div>
      }
        {children}
        <BottomNav />
    </div>
  );
};

export default Layout;
