import './style.css'
import Buttom from '@components/buttons';
function App() {

    const tasks = [
        { id: 1, platform: 'Telegram', bonus: '+10%', icon: 'fab fa-telegram-plane' },
        { id: 2, platform: 'Twitter', bonus: '+10%', icon: 'fab fa-twitter' },
        { id: 3, platform: 'Instagram', bonus: '+10%', icon: 'fab fa-instagram' },
        { id: 4, platform: 'Youtube', bonus: '+10%', icon: 'fab fa-youtube' },
        { id: 5, platform: 'Tiktok', bonus: '+10%', icon: 'fab fa-tiktok' },
    ];
  return (
      <div className="task-list">
            <div className="task-header">
                <h3 className="task-title text-primary">TASK</h3>
                <p className="task-description">Get up to a 50% increase in your referral profit for 30 days after your first deposit</p>
            </div>
            <div className="task-items">
                {tasks.map(task => (
                    <div key={task.id} className="task-item">
                        <i className={`task-icon ${task.icon}`}></i>
                        <span className="task-platform">Subscribe to {task.platform}</span>
                        <span className="task-bonus">{task.bonus}</span>
                        <Buttom label='Start' size='xs'/>
                    </div>
                ))}
            </div>
            <div className="task-footer">
              <p className="activate-bonus">Activate Task Bonus</p>
              <center>
                   <Buttom label='Start' size='lg' icon='fas fa-check'/>
             </center>
            </div>
        </div>
  );
}

export default App;
